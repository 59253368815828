<template>

  <div v-if="session > 0">

    <sales-list-add-new
      :is-add-new-sales-sidebar-active.sync="isAddNewSalesSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <sales-list-filters
      :proyek-filter.sync="proyekFilter"
      :tglpengajuan-filter.sync="tglpengajuanFilter"
      :jenis-filter.sync="jenisFilter"
      :jenis-options="jenisOptions"
      :diskondiminta-filter.sync="diskondimintaFilter"
      :diskondiminta-options="diskondimintaOptions"
      :periodestart-filter.sync="periodestartFilter"
      :periodeend-filter.sync="periodeendFilter"
      :mu-filter.sync="muFilter"
      :mu-options="muOptions"
      :df-filter.sync="dfFilter"
      :df-options="dfOptions"
      :premi-filter.sync="premiFilter"
      :premi-options="premiOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-button
                variant="primary" class="mr-1"
                @click="downloadtemplate"
              >
                <span class="text-nowrap">Download Header</span>
              </b-button>

              <b-button
                variant="primary" class="mr-1"
                @click=""
              >
                <span class="text-nowrap">Download Details</span>
              </b-button>

              <b-button
                variant="primary" class="mr-1"
                @click=""
              >
                <span class="text-nowrap">Download PDF</span>
              </b-button> -->

              <b-button
                variant="primary"
                @click="tambah_pengajuan()"
              >
                <span class="text-nowrap">Tambah Pengajuan Sales</span>
              </b-button>
          
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refSalesListTable"
        class="position-relative"
        :items="fetchSales"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Group Item -->
        <template #cell(sales)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveSalesRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.sitename }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveSalesRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveSalesRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveSalesStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template> -->

        <template #cell(trn_flag_status)="data">
          <a v-if="data.item.trn_flag > 0"
            @click="status_detail(true, data.item.trn_code)">
            <b-badge
              pill
              :variant="`light-${resolveSalesStatusVariant(data.item.trn_flag)}`"
              class="text-capitalize"
            >
              {{ data.item.trn_flag_name }}
            </b-badge>
          </a>
          <b-badge
            v-if="data.item.trn_flag == 0"
            pill
            :variant="`light-${resolveSalesStatusVariant(data.item.trn_flag)}`"
            class="text-capitalize"
          >
              {{ data.item.trn_flag_name }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            
            <b-dropdown-item 
              v-if="data.item.trn_flag == 0 || data.item.trn_flag == 2"
              @click="ubah_pengajuan(data.item.trn_code)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item 
              v-if="data.item.trn_flag == 0"
              @click="showMsgBoxTwo(data.item.trn_code)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item 
              v-if="data.item.trn_flag == 1 || data.item.trn_flag == 3 || data.item.trn_flag == 4"
              @click="preview_pengajuan(data.item.trn_code)">
              <feather-icon icon="SearchIcon" />
              <span class="align-middle ml-50">Preview</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalSales"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <!-- modal -->
      <b-modal
        id="modal-prevent-closing"
        ref="my-modal"
        title="Duplicate"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            label="Bisnis Unit"
            label-for="bu"
          >
            <v-select
              v-model="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="buOptions"
              :clearable="false"
              input-id="bu_id"
              placeholder="Pilih Bisnis Unit"
            />
          </b-form-group>
        </form>
      </b-modal>

      <b-modal id="modal-lg" size="xl" title="Approval Atasan Sales"
        ref="status-modal"
      >
        <b-card>
          <b-row>
            <b-table-simple
              hover
              caption-top
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr class="text-center">
                  <b-th>No</b-th>
                  <b-th>Status</b-th>
                  <b-th>Approval</b-th>
                  <b-th>Level</b-th>
                  <b-th>Tanggal</b-th>
                  <b-th>Catatan</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(val,idx) in tbl_detail_status" :key="idx">
                  <b-td class="text-center">{{ idx+1 }}.</b-td>
                  <b-td>{{ val.status }}</b-td>
                  <b-td>{{ val.approval }}</b-td>
                  <b-td class="text-center">{{ val.app_level }}</b-td>
                  <b-td class="text-center">{{ val.tanggal}}</b-td>
                  <b-td class="text-left">{{ val.catatan }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
        </b-card>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="status_detail(false, '')"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>

      <template #code>
        {{ codeMessageBox }}
      </template>
    </b-card>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, VBModal, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
// buat ambil api
import axios from '@axios'
// buat nampil data
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SalesListFilters from './SalesListFilters.vue'
import useSalesList from './useSalesList'
import salesStoreModule from '../salesStoreModule'
import SalesListAddNew from './SalesListAddNew.vue'
import { codeMessageBox, codePrevent } from './code'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    SalesListFilters,
    SalesListAddNew,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationContent,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      boxOne: '',
      boxTwo: '',
      codeMessageBox,
      codePrevent,
      name: '',
      nameState: null,
      submittedNames: [],
    }
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const SALES_APP_STORE_MODULE_NAME = 'app-sales'

    // Register module
    if (!store.hasModule(SALES_APP_STORE_MODULE_NAME)) store.registerModule(SALES_APP_STORE_MODULE_NAME, salesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SALES_APP_STORE_MODULE_NAME)) store.unregisterModule(SALES_APP_STORE_MODULE_NAME)
    })

    const userDataInfo = ref([])

    const buOptions = ref([])
    const diskondimintaOptions = ref([])
    const muOptions = ref([])
    const statusOptions = ref([])
    onMounted(async () => {
      // const result = await axios.post('http://192.168.254.196:9183/api_bsc/hakAkses/listBu')
      // const dataBU = result.data.data
      // const combo = []
      // // eslint-disable-next-line no-plusplus
      // for (let i = 0, len = dataBU.length; i < len; i++) {
      //   combo.push({ label: dataBU[i].nama_bu, value: dataBU[i].id_bu })
      // }
      // buOptions.value = combo

      const resultDDO = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/listDiskonDiminta`)
      const dataDDO = resultDDO.data[0].data
      const comboDDO = []
      for (let i = 0, len = dataDDO.length; i < len; i++) {
        comboDDO.push({ label: dataDDO[i].trn_diskon_request, value: dataDDO[i].trn_diskon_request })
      }
      diskondimintaOptions.value = comboDDO

      const resultMU = await axios.post(`${useAppConfig().AppUrl}global/listMU`, {bu_id: localStorage.getItem('bisnis_unit')})
      const dataMU = resultMU.data[0].all_mu
      const comboMU = []
      for (let i = 0, len = dataMU.length; i < len; i++) {
        comboMU.push({ label: dataMU[i].cust_code_name, value: dataMU[i].cust_code })
      }
      muOptions.value = comboMU

      const resultStatus = await axios.post(`${useAppConfig().AppUrl}global/listStatusPengajuan`)
      const dataStatus = resultStatus.data[0].data
      const comboStatus = []
      for (let i = 0, len = dataStatus.length; i < len; i++) {
        comboStatus.push({ label: dataStatus[i].nama_status, value: dataStatus[i].id_status })
      }
      statusOptions.value = comboStatus
    })

    const isAddNewSalesSidebarActive = ref(false)

    const jenisOptions = [
      { label: 'Baru', value: 'baru' },
      { label: 'Sisa Kuota', value: 'sisa_kuota' },
    ]
    const dfOptions = [
      { label: 'Ya', value: '1' },
      { label: 'Tidak', value: '0' },
    ]
    const premiOptions = [
      { label: 'Ya', value: '1' },
      { label: 'Tidak', value: '0' },
    ]
    const tbl_detail_status = ref([])
    const {
      fetchSales,
      tableColumns,
      perPage,
      currentPage,
      totalSales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalesListTable,
      refetchData,

      // UI
      resolveSalesRoleVariant,
      resolveSalesRoleIcon,
      resolveSalesStatusVariant,

      // Extra Filters
      proyekFilter,
      tglpengajuanFilter,
      jenisFilter,
      diskondimintaFilter,
      periodestartFilter,
      periodeendFilter,
      muFilter,
      dfFilter,
      premiFilter,
      statusFilter
    } = useSalesList()
    return {
      session,
      // Sidebar
      isAddNewSalesSidebarActive,

      fetchSales,
      tableColumns,
      perPage,
      currentPage,
      totalSales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSalesListTable,
      refetchData,
      buOptions,

      // Filter
      avatarText,

      // UI
      resolveSalesRoleVariant,
      resolveSalesRoleIcon,
      resolveSalesStatusVariant,

      userDataInfo,
      tbl_detail_status,

      // Extra Filters
      proyekFilter,
      tglpengajuanFilter,
      jenisFilter,
      jenisOptions,
      diskondimintaFilter,
      diskondimintaOptions,
      periodestartFilter,
      periodeendFilter,
      muFilter,
      muOptions,
      dfFilter,
      dfOptions,
      premiFilter,
      premiOptions,
      statusFilter,
      statusOptions
    }
  },
  methods: {
    //  rowClass(item, key) {
    //     if (item.cs_category == 'PP') return 'table-success' 
    //   },
    tambah_pengajuan(){
      // alert('tambah')
      // :to="{ name: 'apps-pengajuan-sales-add'}"
      this.$router.push({
        name: 'apps-pengajuan-sales-add', 
        // params: { trn_code: '' }
      })
      localStorage.setItem('trn_code', '')
    },
    async status_detail(sts, flag){
      // alert('status e opo? '+flag);
      if(sts == true){
        const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/detail_status`, {trn_code: flag})
        this.tbl_detail_status = result.data[0]

        console.log(this.tbl_detail_status);  
        this.$refs['status-modal'].show()
      }
      else{
        this.tbl_detail_status = null
        this.$refs['status-modal'].hide()
      }
    },
    ubah_pengajuan(id){
      // alert(trn_code);
      console.log(id);
      localStorage.setItem('trn_code', id)
      this.$router.push({
        name: 'apps-pengajuan-sales-add', 
        // params: { trn_code: '' }
      })
    },
    preview_pengajuan(id){
      // alert(trn_code);
      // console.log(id);
      // localStorage.setItem('trn_code', id)
      this.$router.push({
        name: 'apps-pengajuan-sales-preview', 
        params: { trn_code: id }
      })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      console.log(this.name)
      const params = new URLSearchParams()
      params.append('param', 'duplicate_category')
      params.append('cs_bu', this.name.value)
      params.append('cs_bu_resources', localStorage.getItem('bisnis_unit'))
      params.append('created_by', localStorage.getItem('emp_id'))
      const result = await axios.post('http://192.168.254.196:9183/api_bsc/cat_score/duplicate', params)
      console.log(result)
      // this.refetchData()

      if (result.data.message === 'Berhasil duplicate data') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: result.data.message,
            variant: 'success',
          },
        })
        this.refetchData()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: result.data.message,
            variant: 'danger',
          },
        })
        this.refetchData()
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

    async showMsgBoxTwo(id) {
      this.boxTwo = id
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm('Apakah yakin ingin menghapus data ini?', {
          title: 'Konfirmasi',
          size: 'sm',

          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete_pengajuan_sales')
            params.append('trn_code', id)
            // const result = await axios.post('http://192.168.254.196:9183/api_bsc/cat_score/delete', params)
            const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/deleteList`, params)
            if (result.data.message === 'Berhasil menghapus data') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'BellIcon',
                  text: 'Gagal menghapus data!',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
